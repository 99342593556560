import React, { useState } from "react";
import "./Nav.css";
import "./hamburgers.css";
// import cloudSurfLogo from "../../resources/logos/cloud-logo-black.svg";
import vgLogo from "../../resources/vg-logo.png";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log(pageURI);
  };

  const responsive = menuOpen ? " responsive" : "";

  // const borderRadius = menuOpen ? " topnav-border-radius" : "";

  const hamburgerType = menuOpen ? " is-active" : "";

  const pageURI = window.location.pathname + window.location.search;

  return (
    <React.Fragment>
      <div className={"topnav" + responsive} id="myTopnav">
        <div className="top-strip">
          <span>
            {" "}
            <a href="tel:702-858-1168" className="top-strip-number">
              {" "}
              702-858-1168
            </a>
          </span>
          <a
            href="https://www.facebook.com/dcampbellmtg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook top-strip-icon"></i>
          </a>
          <a
            href="https://www.instagram.com/donovancmpbll/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram top-strip-icon"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/donovan-campbell-1a9002147/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin top-strip-icon"></i>
          </a>
        </div>
        <div className="bottom-strip">
          <div className="flex-item">
            <a href="/" className="top-nav-brand topnav-item topnav-first">
              {/* <img className="topnav-logo" src={cloudSurfLogo} alt="" /> */}
              <span className="topnav-company-name">Donovan Campbell</span>
            </a>
          </div>
          <div className="logo-container">
            <img src={vgLogo} className="vg-logo" />
          </div>
          <a
            href="https://www.facebook.com/dcampbellmtg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook mobile-nav-item"></i>
          </a>
          <a
            href="https://www.instagram.com/donovancmpbll/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram mobile-nav-item"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/donovan-campbell-1a9002147/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin mobile-nav-item"></i>
          </a>

          <button
            className={
              " hamburger hamburger--spin no-bs-border hamburger-icon" +
              hamburgerType
            }
            type="button"
            onClick={toggleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
