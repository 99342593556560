import { API } from "aws-amplify";

const API_NAME = "donovanApi";
const ITEMS_ROUTE = "/items";

const post = async (body) => {
  try {
    const requestInfo = {
      body: body,
    };

    let response = await API.post(API_NAME, ITEMS_ROUTE, requestInfo);
    console.log(response);
    console.log("response was above");
    return response;
  } catch (err) {
    console.error("xxx there was an error in post function in api.js", err);
    console.log(err.message);
    return null;
  }
};

const get = async (id) => {
  try {
    const response = await API.get(API_NAME, ITEMS_ROUTE + `/${id}`);

    return response;
  } catch (err) {
    console.error("there was an error get function in api.js", err);
    return null;
  }
};

// del single row: id/type
const del = async (id, type) => {
  try {
    const response = await API.del(
      API_NAME,
      ITEMS_ROUTE + `/object/${id}/${type}`
    );

    return response;
  } catch (err) {
    console.error("there was an error del function in api.js", err);
    return null;
  }
};

const update = async (updatedItem) => {
  try {
    const requestInfo = {
      body: updatedItem,
    };

    const response = await API.put(API_NAME, ITEMS_ROUTE, requestInfo);

    return response;
  } catch (err) {
    console.error("ERROR in api.js update function: ", err);
    return null;
  }
};

export default {
  get,
  post,
  update,
  del,
};
