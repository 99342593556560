import React, { useState, useEffect } from "react";
import "./ReviewsAdmin.css";
import itemsApi from "../../services/itemsApi";

import { navigate } from "hookrouter";
import ReviewsAdmin from "./ReviewsAdmin";

const PasswordCheck = () => {
  const [userInput, setUserInput] = useState("");
  const [userAuth, setUserAuth] = useState(false);
  useEffect(() => {
    console.log(userInput);
  }, [userInput]);

  const checkPassword = async () => {
    let secret = await itemsApi.get("adminPass");
    let formattedSecret = secret[0].value;
    if (formattedSecret === userInput) {
      setUserAuth(true);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        {userAuth ? (
          <ReviewsAdmin />
        ) : (
          <div className="pw">
            <p>just tell me the password bro</p>
            <input
              type="password"
              onChange={(e) => setUserInput(e.target.value)}
            />
            <button onClick={() => checkPassword()}>submit</button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PasswordCheck;
