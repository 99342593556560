import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";

// import FA from "react-fontawesome";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";

/* ---------- images ---------- */
// import contactImg from "../../resources/email.svg";

/* ---------- lottie animations ---------- */
import loadingWheelLottie from "../../resources/lotties/loading-wheel.json";
import emailLottie from "../../resources/lotties/email-services.json";

/* ---------- components ---------- */
// import Nav from "../Nav/Nav";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import util from "../../services/util";

const Contact = forwardRef((props, ref) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  useEffect(() => {
    setShowFailAlert(false);
  }, [name]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [email]);

  const handleSubmit = () => {
    const templateId = "kbd_contact_form";

    let validEmail = util.checkEmail(email);
    let validName = checkValid(name);

    if (validEmail && validName) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      sendFeedback(templateId, {
        user_message: message,
        user_name: name,
        user_email: email,
        client_email: "donovan@homeapprovers.com",
        client_name: "Donovan",
      });

      setName("");
      setEmail("");
      setMessage("");
    } else {
      console.log("setting error message?");
      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }
      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  // const checkEmail = (email) => {
  //   console.log(email);
  //   const re =
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // };

  const checkValid = (message) => {
    if (message === "" || message === null || message === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const sendFeedback = (templateId, variables) => {
    setShowSendingAlert(true);
    window.emailjs
      .send("gmail", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setShowSuccessAlert(true);
        setShowSendingAlert(false);
        setTimeout(function () {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setShowFailAlert(true);
        setTimeout(function () {
          setShowFailAlert(false);
        }, 3000);
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  return (
    <React.Fragment>
      <div className="contact-background">
        <div className="container contact-container">
          <div className="content-wrapper contact-content-wrapper">
            <LottieAnimation src={emailLottie} width={150} height={150} />
            <h1 className="contact-header">CONTACT</h1>
            <h2 className="contact-info">
              Please reach out for any questions you might have, or if you'd
              like to leave a review!
            </h2>

            <div className="container form-container text-left">
              <form>
                <div className="form-group">
                  <label htmlFor="">Message</label>
                  <textarea
                    name=""
                    id=""
                    className="form-control form-message"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="your-email@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {showSuccessAlert && (
                  <div className="alert success-alert">
                    <p>Email successfully sent!</p>
                  </div>
                )}

                {showSendingAlert && (
                  <div className="alert sending-alert">
                    <p>Sending Email</p>
                    <div className="lottie-container">
                      <LottieAnimation
                        src={loadingWheelLottie}
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}

                {showFailAlert && (
                  <div className="alert fail-alert">
                    <p>{errorMessage}</p>
                  </div>
                )}

                <input
                  type="button"
                  value="Submit"
                  className="contact-submit-btn"
                  onClick={handleSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
});

Contact.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  description: PropTypes.string,
};

Contact.defaultProps = {
  value: 0,
  description: "",
};

export default Contact;
