import React from "react";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
// import "../LottieAnimation/node_modules/bootstrap/dist/css/bootstrap.css";
import "./Home.css";

import { Link } from "react-router-dom";
import vgLogo from "../../resources/proud-member.jpg";

/* ---------- images ---------- */

/* ---------- components ---------- */
import Contact from "../Contact/Contact";
import Reviews from "../Reviews/Reviews";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ImageCarouselHeader from "../ImageCarouselHeader/ImageCarouselHeader";
import workImg from "../../resources/portfolio.png";
import homeImg from "../../resources/home.png";
import shieldImg from "../../resources/shield.png";
import itemsApi from "../../services/itemsApi";

const Home = (props) => {
  // const childReference = useRef(null);

  const apiTest = async () => {
    let newDate = Date.now();
    let body = {
      id: newDate.toString(),
      comment: "hello world",
      approved: false,
      name: "ryan",
      company: "",
      email: "email@email.com",
      date: newDate,
    };
    let response = await itemsApi.post(body);
    // let response = await itemsApi.get("newDate");
    console.log(body);
    console.log(response);
  };

  return (
    <React.Fragment>
      {/* <ScrollHandler /> */}
      <Nav />
      <ImageCarouselHeader />

      <div className="home-container">
        <div className="careers-container">
          {/* START RULE OF 3 */}

          <div className="position-relative overflow-hidden  text-center text-light services-container">
            <div className="row">
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={workImg} alt="Category" className="category-img" />
                </div>
                <h2 className="about-job-header">Professionalism</h2>
                <p className="about-description">
                  Best in class service, availability, and knowledge for all of
                  your mortgage needs. Know your loan’s status 24/7 and never be
                  left guessing what the next step is.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={homeImg} alt="Home" className="category-img" />
                </div>
                <h2 className="about-job-header">Mortgage Services</h2>
                <p className="about-description">
                  Consult with a trusted professional who excels at helping
                  people understand and navigate the mortgage market. Take
                  advantage of these historically low rates and know every cost
                  upfront.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={shieldImg} alt="Shield" className="category-img" />
                </div>
                <h2 className="about-job-header">Strong Ethics</h2>
                <p className="about-description">
                  Feel safe working with someone who’s driven by honesty,
                  integrity, and family values. Get the genuine loan experience
                  you've been looking for.
                </p>
              </div>
            </div>
            <img src={vgLogo} className="vg-logo-home" />
          </div>

          <h2 className="reviews-title">REVIEWS</h2>

          <Reviews />
        </div>
      </div>

      <div id="#contact-section"></div>
      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
