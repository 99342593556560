import React, { useState, useEffect, forwardRef } from "react";
// import PropTypes from "prop-types";

import itemsApi from "../../services/itemsApi";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
import "./Reviews.css";
// import reviews from "./reviews.json";
import { navigate, useRoutes } from "hookrouter";

const Reviews = forwardRef((props, ref) => {
  const reviewPageLength = 3;
  const loadMoreReviews = "See More Reviews";
  const loading = "Loading...";
  const [items, setItems] = useState([]);
  const [abridgedItems, setAbridgedItems] = useState([]);
  const [loadMoreButtonContent, setLoadMoreButtonContent] =
    useState(loadMoreReviews);

  const getRandomAvatar = () => {
    let avatars = [
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/014-koala.svg",

      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/020-fish.svg",

      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/021-octopus.svg",

      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/022-sea+star.svg",

      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/023-goldfish.svg",

      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/home/avatars/034-parrot.svg",
    ];

    return avatars[Math.floor(Math.random() * avatars.length)];
  };

  // const shortenReview = (review) => {
  //   if (review.length > 250) {
  //     return review.slice(250) + "...";
  //   }
  //   return review;
  // };

  const handleLoadMoreReviews = () => {
    setLoadMoreButtonContent(loading);
    let lengthAbridgedItems = abridgedItems.length + reviewPageLength;
    let numAbridgedItems =
      lengthAbridgedItems > items.length ? items.length : lengthAbridgedItems;
    setAbridgedItems(items.slice(0, numAbridgedItems));
    setLoadMoreButtonContent(loadMoreReviews);
  };

  const getReviews = async () => {
    let reviews = await itemsApi.get("review");
    reviews = reviews.filter((obj) => {
      return obj.approved;
    });
    console.log(reviews);
    setItems(reviews);
    if (abridgedItems.length === 0) {
      setAbridgedItems(reviews.slice(0, reviewPageLength));
    }
  };

  useEffect(() => {
    getReviews();
    // setItems(reviews);
  }, []);

  return (
    <React.Fragment>
      <div className="reviews-container">
        <div className="container">
          {abridgedItems.map((item) => (
            <div className="row" key={item.email}>
              <div className="review-container">
                <div className="img-fix">
                  <div className="row">
                    <img
                      src={getRandomAvatar()}
                      alt="Avatar"
                      className="donovan-pic"
                    ></img>
                  </div>
                </div>
                <p className="review-info">
                  <div className="star-rating">
                    <i class="fa fa-star checked" aria-hidden="true"></i>
                    <i class="fa fa-star checked" aria-hidden="true"></i>
                    <i class="fa fa-star checked" aria-hidden="true"></i>
                    <i class="fa fa-star checked" aria-hidden="true"></i>
                    <i class="fa fa-star checked" aria-hidden="true"></i>
                  </div>

                  <span>{item.name}</span>
                  {item.company}
                </p>
                {/* <p className="review-text">{shortenReview(item.review)}</p> */}
                <p className="review-text">{item.comment}</p>
              </div>
            </div>
          ))}
          {items.length !== abridgedItems.length && (
            <button className="load-more-btn" onClick={handleLoadMoreReviews}>
              {loadMoreButtonContent}
            </button>
          )}
          <button
            onClick={() => navigate("/leave-review")}
            className="leave-review-btn"
          >
            Leave a Review
          </button>
        </div>
      </div>
    </React.Fragment>
  );
});

export default Reviews;
