import React, { useState, useEffect } from "react";
import "./LeaveReview.css";
import itemsApi from "../../services/itemsApi";
import Nav from "../Nav/Nav";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import loadingWheelLottie from "../../resources/lotties/loading-wheel.json";
import util from "../../services/util";

const LeaveReview = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userComment, setUserComment] = useState("");
  const [reviews, setReviews] = useState([]);
  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    setShowFailAlert(false);
  }, [userName]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [userEmail]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [userComment]);

  const showAlert = () => {
    setShowSuccessAlert(true);
    setShowSendingAlert(false);
    setTimeout(function () {
      setShowSuccessAlert(false);
    }, 3000);
  };

  const handleSubmit = async () => {
    const body = {
      id: "review",
      email: userEmail,
      name: userName,
      company: userCompany,
      comment: userComment,
      approved: false,
    };

    let validEmail = util.checkEmail(userEmail);
    let validName = checkValid(userName);
    let validComment = checkValid(userComment);

    if (validEmail && validName && validComment) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      setReviews((reviews) => [...reviews, body]);
      setUserEmail("");
      setUserName("");
      setUserComment("");
      setUserCompany("");
      showAlert();
      let response = await itemsApi.post(body);

      console.log(response);
      console.log(reviews);
    } else {
      console.log("setting error message?");
      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }
      if (!validComment) {
        setErrorMessage("Comment cannot be empty");
      }
      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  const checkValid = (message) => {
    if (message === "" || message === null || message === undefined) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Nav />

      <div className="leave-reviews-container">
        <div className="create-review">
          <h3 className="leave-review-header">Leave Review</h3>
          <input
            className="input-review"
            placeholder="Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <br />
          <input
            className="input-review"
            placeholder="Company"
            value={userCompany}
            onChange={(e) => setUserCompany(e.target.value)}
          />
          <br />
          <input
            className="input-review"
            placeholder="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <br />
          <textarea
            className="input-review"
            placeholder="comment"
            value={userComment}
            onChange={(e) => setUserComment(e.target.value)}
          />
          <br />

          <button className="get-in-touch-btn" onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
        {showSuccessAlert && (
          <div className="alert success-alert">
            <p>Email successfully sent!</p>
          </div>
        )}

        {showSendingAlert && (
          <div className="alert sending-alert">
            <p>Sending Email</p>
            <div className="lottie-container">
              <LottieAnimation
                src={loadingWheelLottie}
                height={50}
                width={50}
              />
            </div>
          </div>
        )}

        {showFailAlert && (
          <div className="alert fail-alert">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LeaveReview;
