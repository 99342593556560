import React from "react";
// import About from "../components/About/About";
import Home from "../components/Home/Home";
// import Contact from "../components/Contact/Contact";
import ReviewsAdmin from "../components/ReviewsAdmin/ReviewsAdmin";
// import Projects from "../components/Projects/Projects";
// import Services from "../components/Services/Services";
import { BrowserRouter } from "react-router-dom";
import ScrollHandler from "../components/ScrollHandler/ScrollHandler";
import PasswordCheck from "../components/ReviewsAdmin/PasswordCheck";
import LeaveReview from "../components/LeaveReview/LeaveReview";

const routes = {
  "/": () => (
    <BrowserRouter>
      <ScrollHandler />
      <Home />
    </BrowserRouter>
  ),
  // "/about": () => <About />,
  // "/contact": () => <Contact />,
  "/admin": () => <PasswordCheck />,
  "/leave-review": () => <LeaveReview />,
  // "/projects": () => <Projects />,
  // "/services": () => <Services />,
};
export default routes;
