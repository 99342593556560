import React, { useState, useEffect } from "react";
import "./ReviewsAdmin.css";
import itemsApi from "../../services/itemsApi";

const ReviewsAdmin = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userComment, setUserComment] = useState("");
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    let reviews = await itemsApi.get("review");
    reviews = reviews.filter((obj) => {
      return obj.approved === false;
    });
    console.log(reviews);
    setReviews(reviews);
  };

  const approveReview = async (review) => {
    if (!review.approved) {
      review.approved = true;
      let response = await itemsApi.update(review);
      let updatedReviews = reviews.filter((obj) => {
        return obj.email !== review.email;
      });
      setReviews(updatedReviews);
    }
  };

  const createReview = async () => {
    const body = {
      id: "review",
      email: userEmail,
      name: userName,
      company: userCompany,
      comment: userComment,
      approved: false,
    };
    setReviews((reviews) => [...reviews, body]);
    setUserEmail("");
    setUserName("");
    setUserComment("");
    let response = await itemsApi.post(body);

    console.log(response);
    console.log(reviews);
  };

  const deleteReview = async (review) => {
    //   review.approved = true;
    let response = await itemsApi.del(review.id, review.email);
    console.log(response);
    let updatedReviews = reviews.filter((obj) => {
      return obj.email !== review.email;
    });
    setReviews(updatedReviews);
  };

  return (
    <React.Fragment>
      <h3 className="header">Pending Reviews</h3>
      <div className="pending-reviews">
        {reviews.map((review) => {
          return (
            <div className="review-container" key={review.email}>
              <p className="review-header">
                {" "}
                From: {review.name}, {review.company}, {review.email}
              </p>
              <p className="review-subheader"> Comment: {review.comment}</p>

              <p>Approve review and post online?</p>
              <button onClick={() => approveReview(review)}>Approve</button>
              <button onClick={() => deleteReview(review)}>Delete</button>
            </div>
          );
        })}
      </div>
      <div className="create-review">
        <h3 className="header">Create Review</h3>
        <input
          className="input"
          placeholder="Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <br />
        <input
          className="input"
          placeholder="Company"
          value={userCompany}
          onChange={(e) => setUserCompany(e.target.value)}
        />
        <br />
        <input
          className="input"
          placeholder="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <br />
        <textarea
          className="input"
          placeholder="comment"
          value={userComment}
          onChange={(e) => setUserComment(e.target.value)}
        />
        <br />
        <button className="reviews-btn" onClick={() => createReview()}>
          Submit
        </button>
      </div>
    </React.Fragment>
  );
};

export default ReviewsAdmin;
